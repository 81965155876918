.container {
  display: flex;
  flex-direction: column;  
  position: relative;

	line-height: 1.3;
	// letter-spacing: 1.5px;  
}       

.title {
	position: relative;
  // margin: 0 auto;
  
	display: flex;
	align-items: center;
	justify-content: center;

  animation: fade-top 3s;

	img {
		max-width: 100vw;
		z-index: 1;
	}
	
  h1 {
		position: absolute;
		z-index: 2;
		top: 60%;						
		font-size: 8rem;
		font-weight: bold;
		color: var(--black);
		text-shadow: 4px -3px 1px var(--white);
  }
}


.aboutIntro {
	transform: translate(0px);
	text-align: right;
	padding-right: 9vw;
	margin-bottom: 1rem;
	h2 {
		overflow: hidden;
		animation-duration: 5s;
  	animation-name: introText;		
		font-size: 2.2rem;	
	}
}

.aboutTalk {
	p {
		padding: 1rem;
    font-size: 1.5rem;
	}

	img {
		width: 100%;
	}
}
 

.aboutDescription {
	padding: 1rem;

	img {
		width: 100%;
	}

	p {
		font-size: 1.5rem;	
	}
}

// .aboutDescriptionContainer {
//     margin: 2rem 1rem;
//     padding: 0 10vw; 
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     /* separa as colinas na lateral esquerda direita */
//     gap: 1rem;    
// }

.descriptionCard {
	display: block;
}

.aboutDescriptionTalk{
	display: flex;
	flex-direction: column;
	
	align-items: center;

	margin: 1rem;

	line-height: 1.2;
	position: relative;
		
	img {
		width: 100%;
		margin: 1rem;
		position: relative;
		filter: drop-shadow(1px 1px 2px gray);
	}
		
	p {
		border-top: 0.5rem solid orange;
		border-left: 0.5rem solid orange;
		padding: 0.5rem;
		width: 70%;		
		position: absolute;
		right: 1%;
		bottom: 5%;


		font-size: 1.2rem;
		text-align: center;

		color: var(--about-text);
		background: linear-gradient(110deg, var(--container-bg), 80%, rgba(214, 138, 233, 0.842));
	}
}


.aboutBarnnerContainer {
    margin: 0rem 1rem;
    padding: 2rem 10vw; 
    display: flex;
}

.aboutBannerImg {
    max-width: 100%;
	filter: sepia(80%) drop-shadow(8px 8px 10px gray);
}

@media screen and (min-width: 900px){
	.title {
		h1 {
		  top: 50%;						
		  font-size: 10rem;
		}
	}
	.aboutTalk {
		display: flex;
		flex-direction: row-reverse;

		justify-content: space-around;
		align-items: center;

		background: linear-gradient(140deg,  var(--container-bg), 70%, rgba(161, 82, 180, 0.7));
		p {
			width: 50vw;
		}

		img {
			padding: 1rem;
			width: 40%;
		}
	}

	.aboutDescription {
		display: flex;
		flex-direction: column;

		// justify-content: right;
		align-items: flex-end;

		background: linear-gradient(240deg,  var(--container-bg), 65%, rgba(161, 82, 180, 0.7), 65%, rgba(212, 108, 238, 0.342));
		filter: drop-shadow(-18px -8px 5px rgb(160, 59, 160));
		
		// border-bottom: 0.5rem ridge rgba(179, 10, 221, 0.03);
		p {
			width: 50vw;
		}
	}
	
	
	.descriptionCard {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

    // background: linear-gradient(150deg, var(--container-bg), 90%, rgba(214, 138, 233, 0.8));
	}


	.aboutDescriptionTalk{
		margin: 0 0.5rem;
				
		img {
			width: 100%;
			// margin: 1rem;
			position: relative;
		}
			
		p {
			right: 3%;
			bottom: 7%;
	
			padding: 1.5rem;
			font-size: 1.8rem;
		}
	}

  .aboutBarnnerContainer {
    margin: 0;
    // background: linear-gradient(90deg, var(--container-bg), 90%, rgba(214, 138, 233, 0.8));
  }
}


@keyframes fade-top {
	0% {
        transform: matrix(1,0,0,1,0,0); 
        clip-path: polygon(0% 160%, 0% 0%, 0% 0%, 0% 0%, 0% 250%);
        opacity: 0;
        animation-timing-function: ease-in-out;
    }
    80% {
        transform: matrix(1,0,0,1,0,-30); 
        clip-path: polygon(100% 0%, 0% 0%, 0% 0%, 0% 100%, 100% 100%);
        opacity: 1;
        animation-timing-function: ease-in-out;
    }
}

@keyframes fade-right {
    0% {
        position: absolute;        
        opacity: 0; 
        transform: scale(0);
        left: -6rem;
        animation-timing-function: linear;
    }

	100% {
        opacity: 1; 
        animation-timing-function: ease-in-out;
    }
}

@keyframes introText {
	0% {
		opacity: 0;
		transform: rotate(20deg);
	}
	
	50% {
		color: silver;
		transform: translate(0%);
	}
	
	80% {
		transform: rotate(0);
	}
	100% {
		opacity: 1;
	}
}