.contact {
  display: flex;
  flex-direction: column;
  
  align-items: center;
}

.contactTitle {
  font-size: 3rem;
  margin-top: 1rem;
}

.contactBlock {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  img {
    width: 20vmax;
  }
}

.contactLinkBox {
  margin: 1rem 0 ;
  display: flex;
  flex-direction: column;
  
  // width: 50vmax;

  transition: all 0.3s;

  
  p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  svg {
    font-size: 2.5rem;
    padding: 0.25rem;
  }

  // a {
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
    
  //   padding: 1.2rem;
  //   margin: 0.35rem;
    
  //   text-align: center;
  //   background-color: var(--green-opacity);
  //   color: var(--black);
  //   border-radius: 2rem;

  //   &:hover, &:active {
  //     background-color: var(--green-light);
  //     color: white;
  //   }
  // }

}


@media screen and (min-width: 600px) {
  .contactBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    margin: 2rem 5vmax;
  }  
}



// .inputBlock {
//   max-width: 90%;
//   padding: 1rem; 
// }
// .inputBlock input {
//   display: flex;
// }
// .inputBlock input, .inputBlock textarea {
//   width: 100%;
//   outline: none;
//   padding: 0.1rem 0.5rem;
  
//   color: var(--text-input);
  
//   border: none;
//   border-bottom: 2px solid var(--border-btn);
//   border-radius: 5px;

//   transition: linear .3s;
// }
// .inputBlock input::placeholder, .inputBlock textarea::placeholder {
//   color: var(--text-placeholder);
// }
// .inputBlock input:focus, .inputBlock textarea:focus {
//   border: none;
//   box-shadow:none;
//   border-left: 15px solid var(--border-left);
//   background-color: var(--input-focus);   
// }
