footer {
  background: linear-gradient(var(--green-light), 15%, rgba(255, 255, 255, 0.5));
}

footer .footerNav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	position: relative;
}

footer .footerNavgation {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 0 auto;
	margin-top: 2%;

	text-transform: uppercase;

	
	a {
		font-size: 90%;		
		text-decoration: none;
		color: var(--black-light);
		font-weight: 600;

		transition: all 0.2s linear;

		&:hover {
			color: var(--footer-text);
		}
	}
}

.footerSocialMedia {
	padding: 1rem 2rem;
	
	
	a {
		margin: 0 1rem;
	}
	
	svg {
		font-size: 2rem;
		color: var(--black-light);
		
		transition: all 0.5s linear;
		
		&:hover {
			// filter: drop-shadow(3px 3px 3px rgb(0, 0, 0));
			transform: scale(1.5);
			color: var(--black);
		}
	}
	
}

.copyright {
	display: flex;
	justify-content: space-between;

	/* margin: 1rem 0; */
	margin-top: 1rem;
	padding: 0.7rem 2rem;
	border-top: 3px solid var(--footer-text);
	color: var(--footer-text);

	div {
		display: flex;
	}

	img {
		margin: auto 0;
		margin-right: 15px;
		width: 20px;
		height: 20px;
	}

	a {
		text-decoration: none;
		color: var(--footer-text);
		transition: all 0.3s linear;

		&:hover {
			font-weight: bold;
			color: var(--footer-hover);
			border-bottom: 2px solid var(--footer-text);
		}

		&:active {
			color: greenyellow;
		}
	}
}

@media screen and (max-width: 600px) {
	footer .footerNav {
		display: grid;
		grid-template-columns: 1fr;
	}

	.footerSocialMedia {
		display: flex;
		justify-content: flex-end;
	}
}

// @media screen and (max-width: 450px) {
//     footer .footerNav {
//         display: grid;
//         grid-template-columns: 1fr;
//     }

//     footer .footerNavgation a {
//         font-size: 0.9rem;
//     }

//     footer .footerNavgation a:hover {
//         font-size: 1rem;
//     }
// }

// @media screen and (max-width: 380px) {
//     footer .footerNavgation a:hover {
//         font-size: 0.9rem;
//     }
// }
