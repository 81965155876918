.containerAboutIsabel {
  display: flex;
  flex-direction: column-reverse;

  justify-content: center;
  align-items: center;
}

.imgAboutIsabel {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 2rem 0.5rem;

  position: relative;
  z-index: 0;

  img {
    z-index: 1;
    width: 100%;
    height: auto;  
    padding: 2rem;
  }
  
  .imgBoxBackground {
    width: 100%;
    height: 40vh;
    margin-top: -35vh;
    
    background-color: rgba(235,218,188, 1);
    border-color: rgba( 0, 0, 0, 0);
  }
}

.textAboutIsabel {
  display: flex;  
  flex-direction: column;
  margin: 0 2rem;
  
  p {
    font-size: 1.5rem;  
  }
}

.title {
  z-index: 2;
  position: relative;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 2.2rem;
    // box-shadow: rgba(240, 156, 46, 0.4) 5px 5px, rgba(240, 149, 46, 0.3) 10px 10px, rgba(240, 162, 46, 0.2) 15px 15px, rgba(240, 195, 46, 0.1) 20px 20px, rgba(240, 140, 46, 0.05) 25px 25px;
  }
}

.containerAboutDescription {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 2rem 0.5rem;
}

.textDescription {
  // display: flex;  
  margin: 0 2rem;
  
  p {
    padding: 1rem;
    font-size: 1.5rem;  
  }
}


.imgAboutDescription {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 2rem 0.5rem;

  position: relative;
  z-index: 0;

  img {
    z-index: 1;
    width: 100%;
    height: auto;  
    padding: 2rem;
  }
  
  div {
    // transform: translate(0px, -6.9917px) scale(1, 42vw);
    width: 100%;
    height: 40vh;
    margin-top: -35vh;
    
    background-color: rgba(189, 119, 28, 0.555);
  }
}


.footerAboutDescription {
  display: none;
}

@media screen and (min-width: 900px){
  .containerAboutIsabel {
    display: flex;
    flex-direction: row;
  }

  .containerAboutDescription {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .footerAboutDescription {
    img {
      filter: drop-shadow(1px 5px 3px rgb(90, 80, 63));
      background: linear-gradient(30deg, rgb(190, 131, 29), 20%, rgb(255, 255, 255));
    }
  }
}

@media screen and (max-width: 300px){
  
}


@keyframes leftToCenter {
  0% {
    transform:  translateX(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}
@keyframes rightToCenter {
  0% {
    transform:  translateX(100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes buttonToTop {
  0% {
    transform:  translateY(100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes topToButton {
  0% {
    transform:  translateY(-100%);
    opacity: 0;
  }
  100% {
    /* transform: rotate(0); */
    opacity: 1;
  }
}

@keyframes topLeftToButton {
  0% {
    transform: rotate(-25deg) translateY(-100%) translateX(-50%);
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}
