.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
      
  font-size: inherit;

  width: 100%;
  margin: 0.35rem 0;
  padding: 1rem 2rem;
  border: none;
  border-radius: 14px;
    
  color: inherit;
  // background: linear-gradient(180deg, var(--yellow) 10%, var(--orange) 100%);
  background: var(--btn-background-orange);
  transition: all 0.3s;
   
  &:not(:disabled):hover {
    box-shadow: 1px 1px 11px 1px black;
  }

  &:not(:disabled):active {
    box-shadow: none;
    // background: linear-gradient(170deg, var(--purple) 10%, var(--purple-light) 100%);
    
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.backgroundOrange {
  background: var(--btn-background-orange);

  &:not(:disabled):active {
    background: var(--btn-background-orange-active);
  }
}

.backgroundGreen {
  background: var(--btn-background-green);

  &:not(:disabled):active {
    background: var(--btn-background-green-active);
  }
}

.backgroundPink {
  background: var(--btn-background-pink);

  &:not(:disabled):active {
    background: var(--btn-background-pink-active);
  }
}