@import 'color.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Shippori Mincho B1', serif;
  font-size: 20px;
  
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--text-link);
}

@media screen and (max-width: 900px) {
  * {
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  * {
    font-size: 12px;
  }
}

/* dysplay do Sansumg k51 pelo instagram em 2021*/
@media screen and (max-width: 300px) {
  * {
    font-size: 9px;
  }
}