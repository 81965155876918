:root {
  --white: #fff;
  
  --black: black;
  --black-opacity: rgba(0, 0, 0, 0.5);
  --black-light: rgba(0, 0, 0, 0.6);
  
  --green: rgb(110, 141, 63);
  --green-opacity: rgba(170, 198, 128, 0.631);
  --green-light: rgb(171, 199, 129);
  --green-dark: hsl(86, 68%, 14%);

  --yellow: rgba(240, 237, 46, 1);
  --yellow-opacity: rgba(240, 237, 46, 0.6);
  --yellow-light: rgb(255, 255, 179);
  --yellow-dark: rgb(128, 128, 0);

  --orange: rgb(244, 164, 96);
  --orange-opacity: rgba(244, 164, 96, 0.6);
  --orange-light: rgb(253, 226, 203);
  --orange-dark: rgba(255, 123, 0, 0.808);

  --pink:rgb(255, 157, 157);
  --pink-opacity: rgba(255, 157, 157, 0.6);
  --pink-light: rgba(255, 157, 157, 0.855);

  --purple: rgb(128, 0, 128);
  --purple-opacity: rgb(128, 0, 128, 0.6);
  --purple-light: rgba(255, 72, 224, 0.855);
  --purple-dark: rgb(51, 0, 51);
  
  --header-text: var(--with);
  --header-hover: var(--black);
  --header-background: var(--green-light);
  --header-mini-menu-background: var(--orange-opacity);

  --footer-text: var(--header-text);
  --footer-hover: var(--header-hover);
  --footer-background: var(--header-background);

  --nt-bg: var(--orange-dark);
  --nt-title: var(--black);
  --nt-text: var(--with);

  --about-text:                 var(--black-light);
  --container-bg:             rgb(255, 246, 255);
   
  --border-left:              rgba(248, 159, 143, 0.7);
  --text-color-clean:         white;

  --btn-background-pink:        linear-gradient(180deg, var(--pink-opacity) 10%, var(--pink-light) 100%);
  --btn-background-pink-active:        linear-gradient(180deg, var(--pink-light) 10%, var(--pink) 100%);
  --btn-background-orange:        linear-gradient(180deg, var(--orange-opacity) 10%, var(--orange-light) 100%);
  --btn-background-orange-active:        linear-gradient(180deg, var(--orange-light) 10%, var(--orange) 100%);
  --btn-background-green:       linear-gradient(180deg, var(--green-opacity) 10%, var(--green-light) 100%);
  --btn-background-green-active:  linear-gradient(180deg, var(--green-light) 10%, var(--green) 100%);
/*   
  --contact-text:  rgba(244, 165, 96, 0.9);
  
  --text-input: var(--pink-light);
  --text-placeholder: rgba(253, 135, 71, 0.6);
  --input-focus: rgba(248, 159, 143, 0.2);

  --border-btn: rgb(255, 200, 190);
  --text-color-btn: rgb(253, 250, 250);
  --background-btn: rgba(244, 165, 96, 0.9);
  --background-btn-hover: rgb(255, 10, 182);
  --background-btn-active: rgba(21, 13, 22); */
}