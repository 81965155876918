$size: 4.5rem;
$shadow: 2;
$backgroundYellow: rgba(250, 250, 191, 0.5);

.title {
  font-size: $size / 2;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.2;
}

.imageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    padding: 0.25rem;
    max-width: 100%;
    max-height: 95%;
  }

  a {
    width: 100%;
  }
}

.hideOnSmallScreen {
  display: none;
}

// fim do generico \\

.containerIntro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vmin;
  background: url("../assets/imgs/floralTherapies/orangeFlower.jpg") no-repeat left / 120%;

  animation: slidingBackground 40s linear infinite;
}

.introTitle {
  font-size: $size;
  color: var(--yellow);
  text-shadow: 1px 5px 5px var(--black);
  text-transform: uppercase;
  letter-spacing: 2vw;

  // mix-blend-mode: lighten;

  // &:before {
  //   color: $ternary;
  //   content: attr(data-text);

  //   position: absolute;
  // }
}

.containerAboutFlowerTherapy,
.containerFloralService,
.containerHowFlowerTherapyCameAbout,
.containerEdwardBach {
  display: flex;
  flex-direction: column;

  padding: 1rem;
}

.containerAboutFlowerTherapy {
  .buttonToDownloadSupplementaryMaterialOnFloralTherapy {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      button {
        justify-content: center;
      }
      font-size: $size / 3.5;
      padding: 0.25rem;
      width: 100%;
    }
  }

  .containerAboutFlowerTherapyImage {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 35vh;
      border-bottom-right-radius: 50% 60%;
      border-top-left-radius: 50% 60%;
      box-shadow: 10px 10px 5px 3px var(--green);
      transform: skew(-3deg, -5deg) scale(0.8) translate(1%, -1%);
    }
  }

  .aboutFlowerTherapyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  
    img {
      background: linear-gradient(170deg, var(--green-light) 10%, var(--pink-opacity) 100%);
      margin: 0.25rem;
      padding: 0.5rem;
  
      border-bottom-right-radius: 50% 60%;
      border-top-left-radius: 50% 60%;
      box-shadow: 1px 5px 5px var(--green);
      width: 98%;
      max-height: 5vh;
    }
  }
}

.containerFloralService {
  .descriptionFloralService {
    width: 100%;
    padding: 0.5rem;
  }

  .containerFloralServiceCard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  
    img {
      width: 98%;
      max-height: 75vh;
      padding: 0.5rem;
      margin: 0.25rem 0;
      background: linear-gradient(30deg, var(--green-light) 40%, var(--green-opacity) 90%);
      box-shadow: 1px 1px 2px black;
    }
  }
}

.containerHowFlowerTherapyCameAbout {
  // border-top: 0.25rem solid var(--green-light) ;

  display: flex;
  flex-direction: column-reverse;

  .containerHowFlowerTherapyCameAboutImg {
    display: flex;
    justify-content: center;

    transform: skewX(-4deg) scale(0.85);

    img {
      box-shadow: 5px 5px 2px 0px var(--green-dark);
      border: 0.75rem inset var(--orange);
      border-radius: 50%;
      // transform: scale(0.95);
      height: 100%;
      width: 100%;
    }
  }
}

.containerEdwardBach {
  // background: linear-gradient(90deg, var(--green) 30%, var(--orange-opacity) 90%);
  // gap: 1rem;

  .containerEdwardBachImg {
    display: flex;
    justify-content: center;

    transform: skewX(5deg) scale(0.85);
    box-shadow: 5px 1px 5px 0px var(--black);

    background-color: var(--orange);
    img {
      border: 0.5rem inset var(--orange-opacity);
      transform: scale(0.95);
      width: 100vmin;
      height: 100vmin;
    }

    // segunda ocorrencia do "containerEdwardBachImg"
    & ~ div {
      transform: skewX(-5deg) scale(0.85);
      background-color: var(--green);

      img {
        border: 0.5rem inset var(--green-opacity);
      }
    }
  }
}

.contactLinkBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-shadow: 5px 5px 0px 0px var(--black-opacity);

  margin: 1rem 0;
  padding: 0.25rem;
  border-radius: 5px;

  transition: all 0.3s;

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $size / 3;
    margin-bottom: 0.5rem;
  }

  svg {
    font-size: 2.2rem;
    padding: 0.25rem;
  }

  a {
    font-size: $size / 3.5;
    width: 100%;
    padding: 0 0.25rem;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.containerText {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    padding: 0.25rem;
  }

  p,
  a {
    font-size: $size / 3;
  }

  a:hover {
    color: rgb(143, 19, 143);
    text-decoration: underline;
  }
}

@media (min-width: 700px) {
  .containerAboutFlowerTherapy {
    // background: linear-gradient(110deg, var(--green-opacity) 30%, var(--green) 60%);

    .containerAboutFlowerTherapyImage {
      // background: linear-gradient(90deg, var(--green) 30%, var(--green-light) 100%);

      img {
        padding: 1rem;
        background: linear-gradient(90deg, var(--green-light) 30%, var(--green) 100%);

        border-radius: 10%;
        max-width: 100%;
        max-height: 45vh;
        box-shadow: 10px 10px 5px 3px var(--green-dark);
        transform: skew(-10deg, 5deg) scale(0.85);
      }
    }
  }

  .containerFloralService {
    .containerFloralServiceButton {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .contactLinkBox {
        grid-column: 2;
        margin: 0.5rem;
      }
    }

    .backgroundFloralService {
      padding: 10rem 0;
      background: url("../assets/imgs/floralTherapies/isa-06.jpg") no-repeat left / 150%;
      animation: slidingBackgroundIsabel 20s linear infinite;
    }

    .hideOnSmallScreen {
      display: none;
    }
  }

  .containerHowFlowerTherapyCameAbout {
    border-top: 0.25rem solid var(--green-light);
    background: linear-gradient(90deg, var(--green-opacity) 30%, var(--green-light) 90%);

    .containerHowFlowerTherapyCameAboutImg {
      img {
        transform: scale(0.95);
      }
    }
  }

  .containerEdwardBach {
    border-top: 0.25rem solid var(--green);
    background: linear-gradient(90deg, var(--green-opacity) 30%, var(--green-light) 90%);

    .containerEdwardBachImg {
      transform: skewX(5deg) scale(0.85);
      box-shadow: 0px 0px 0px 0px var(--black);

      background-color: transparent;
      img {
        width: 70%;
        height: 70vmin;
      }

      // segunda ocorrencia do "containerEdwardBachImg"
      & ~ div {
        transform: skewX(-5deg) scale(0.85);
        background-color: transparent;

        img {
          border: 0.5rem inset var(--green-opacity);
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1rem;
  }

  .containerAboutFlowerTherapy {
    article {
      margin: 2rem 0.5rem;
    }

    .containerAboutFlowerTherapyImage {
      img {
        max-height: 65vh;
      }
    }
  }

  .containerFloralService {
    .hideOnSmallScreen {
      display: flex;
    }

    .containerFloralServiceTitle {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .containerFloralServiceImage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: var(--green);
      border-radius: 5px;
      box-shadow: 5px 5px 3px 0px var(--green-dark);
      transform: skewX(-4deg) scale(0.85);

      overflow: hidden;
    }

    .backgroundFloralService {
      padding: 10rem 0;
      background: url("../assets/imgs/floralTherapies/isa-vivencia_01.jpg") no-repeat;
      animation: slidingBackgroundIsabel 20s linear infinite;
    }
  }

  .containerHowFlowerTherapyCameAbout {
    border-top: 0.25rem solid var(--green-light);
    border-bottom: 0.25rem solid var(--green);
    gap: 1rem;
  }

  .containerEdwardBach {
    // background: linear-gradient(90deg, var(--green) 30%, var(--orange-opacity) 90%);
    // background: linear-gradient(90deg, var(--purple-opacity) 30%, var(--orange-opacity) 90%);
    background: linear-gradient(90deg, var(--green-opacity) 30%, var(--green) 90%);
    gap: 1rem;

    .containerEdwardBachImg {
      box-shadow: 5px 1px 5px 0px var(--black);

      background: linear-gradient(90deg, var(--green) 30%, var(--green-dark) 90%);

      img {
        width: 100%;
        height: 100%;
      }

      // segunda ocorrencia do "containerEdwardBachImg"
      & ~ div {
        background: var(--purple-light);
      }
    }
  }
}

@keyframes slidingBackground {
  0%,
  100% {
    background-position: 0 40%;
  }

  50% {
    // background: url("../assets/imgs/floralTherapies/flor-amarela.jpg") no-repeat left / 120%;
    background-position: 100% 40%;
  }

  25% {
    background-position: 50% 70%;
  }
}

@keyframes slidingBackgroundIsabel {
  0%,
  100% {
    background-position: 0 20%;
    box-shadow: 10px 10px 5px 0px var(--green-light);
    margin: 1rem;
  }

  50% {
    background-position: 100% 40%;
    box-shadow: -10px -10px 5px 0px var(--green-light);
  }

  25% {
    background-position: 60% 50%;
  }
}
