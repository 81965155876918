header {
  // overflow: hidden;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(36deg, var(--green-light), 15%, rgba(255, 255, 255, 0.5));

  padding: 1rem;

  a {

    font-size: 90%;
    color: var(--black-light);
    line-height: -12px;
    text-decoration: none;
    margin-left: 20px;
  
    transition: border-bottom 0.2s linear, color 0.2s linear;

    &:hover {
      color: var(--black);
    }
  }
}

.maxMenu {
  display: flex;
  color: var(--header-text);
  text-transform: uppercase;
  border-radius: 3%;
}

.logoLink:hover {
  border: none;
}

.logoLink img {
  margin: auto 0;
  margin-right: 15px;
  width: 35px;
  height: 35px;

  transition: all 0.5s ease-in-out;
}

.logoLink img:hover {
  transform: scale(1.5);
}

.miniMenu {
  display: none;
}

.menu {
  background: var(--green-light);
  border-radius: 25%;
  width: 45px;
  height: 45px;
  position: relative;
  top: 20px;
  bottom: 0px;
  right: -10px;
}

.hamburguer {
  position: relative;
  display: block;
  background: var(--header-hover);
  width: 25px;
  height: 3px;
  top: 20px;
  left: 10px;
  transition: 0.05s ease-in-out;
}

.hamburguer:before,
.hamburguer:after {
  background: var(--header-hover);
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.05s ease-in-out;
}

.hamburguer:before {
  top: -10px;
}

.hamburguer:after {
  bottom: -10px;
}

input {
  display: none;
}

input:checked ~ label .hamburguer {
  transform: rotate(45deg);
}

input:checked ~ label .hamburguer:before {
  transform: rotate(90deg);
  top: 0;
}

input:checked ~ label .hamburguer:after {
  transform: rotate(90deg);
  bottom: 0;
}

@media screen and (max-width: 600px) {
  header {
    padding: 0rem;

    a {
      color: var(--black);
      margin-bottom: 0.25rem;
    }
  }

  .maxMenu {
    display: none;
  }

  .logoLink {
    display: none;
  }

  .miniMenu {
    display: grid;
    position: relative;
    color: var(--header-text);
    text-transform: uppercase;
    border-radius: 3%;
  }

  .miniMenu label {
    padding-bottom: 3rem;
  }
}
