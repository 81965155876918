.container {
  // display: flex;
  // flex-direction: column;  
  // position: relative;
  font-size: 100%;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 2rem;

  h1 {
    font-size: 4rem;
  }
}

.subTitle {
  font-size: 3rem;
}

.containerDescription {
  display: flex;
  flex-direction: column;  

  background: linear-gradient(180deg, var(--green), 75%, var(--green-dark), 40%, rgb(255, 255, 255));

  a {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  img {
    max-width: 80%;
    padding: 1.5rem;
    filter: drop-shadow(1px 5px 3px rgb(90, 80, 63));
    background: linear-gradient(280deg, var(--green), 20%, var(--green-opacity), 70%, rgb(255, 255, 255));
  }
}

.containerDescriptionText {
  margin: 1rem;
  padding: 1rem;
  
  p {
    font-size: 1.5rem;
  }

  a {
    font-size: 1.5rem;  
    transition: all 0.5s linear;
  }

  a:hover {
    color: rgb(224, 13, 143);
  }
  
}


.containerImage {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  
  img {
    padding: 1rem;
    width: 100%;
    animation: leftToCenter 3s 1 ease-in-out;
    transition: all 0.4s linear;
  }
}




@keyframes leftToCenter {
  0% {
    transform:  translateX(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@media screen and (min-width: 900px) {
  .title {
    filter: drop-shadow(1px 3px 2px rgb(22, 14, 1));
    h1 {font-size: 5rem;}
  }

  .containerDescription {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 1rem;
    
    background: linear-gradient(180deg, var(--green), 16%, var(--green-opacity), 7%, rgb(255, 255, 255));

    img {
      transform: scale(.82) skew(-6deg);
      padding: 1.5rem;
      filter: drop-shadow(1px 5px 3px rgb(90, 80, 63));
      background: linear-gradient(280deg, var(--green), 20%, var(--green-opacity), 70%, rgb(255, 255, 255));
    }
  }

  .containerDescription1 {
    width: 100%;
    padding: 2rem;
    margin: 0 auto;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  // .divisorWeb {
  //   display: none;
  //   width: 1rem;
  //   background: rgb(185, 184, 184);
  //   position: relative;
  //   height: 24vw;
  // }
}

@media screen and (max-width: 300px) {
  .title h1 {
    font-size: 3rem;
  }
}